.contacts {
  .site__heading {
    margin-bottom: 0;
  }

  &__block {
    margin: 10px 0 0;
    @include grid(.75fr 1fr, start, 116px);

    @include media($md) {
      display: block;
    }

    .block {
      &__meta {
        @include flex($gap: 40px, $flow: column);

        @include media($md) {
          gap: 30px;
        }
      }

      &__map {
        position: relative;
        height: calc(100% + 90px);
        margin-top: -90px;
        border: 2px solid $black;
        z-index: 1;

        @include media($md) {
          margin-top: 24px;
          height: 425px;
        }

        & > * {
          width: 100%;
          height: 100%;
        }
      }

      &__text {
        @include font(14px, 500, 20px, $black);

        &_bold {
          font-weight: 600;
        }
      }
    }
  }

  .hero__socials {
    margin-top: 0;

    @include media($md) {
      display: flex;
    }
  }
}