@import "_variables";

@mixin font($size: 16px, $weight: 400, $lineHeight: 1.2, $color: $black, $align: 0){
  color: $color;
  font-size: $size;
  @if $weight > 0 {
    font-weight: $weight;
  }
  line-height: $lineHeight;
  @if $align != 0 {
    text-align: $align;
  }
}

@mixin flex($align: flex-start, $justify: flex-start, $gap: 0, $flow: row wrap){
  display: flex;
  flex-flow: $flow;
  align-items: $align;
  justify-content: $justify;
  @if $gap {
    gap: $gap;
  }
}

@mixin grid($template: 1fr, $align: center, $gap: 0){
  display: grid;
  grid-template-columns: $template;
  align-items: $align;
  @if $gap {
    gap: $gap;
  }
}

@mixin svgColored($color: #000, $width: 100%, $height: $width){
  svg {
    width: $width;
    height: $height;
    color: $color;
    transition: .3s ease;
  }
}

@mixin media($width: $md){
  @media(max-width: $width){
    @content;
  }
}