.modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.65);
  backdrop-filter: blur(15px);
  opacity: 0;
  visibility: hidden;
  z-index: 10000;
  transition: .3s ease;

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    max-width: 516px;
    padding: 40px;
    background: $white;
    border: 2px solid $black;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease;

    @include media($md) {
      padding: 40px 20px;
      max-width: calc(100% - 40px);
    }

    &__close {
      position: absolute;
      top: 11px;
      right: 11px;
      width: 25px;
      height: 25px;
      cursor: pointer;

      @include svgColored($black, 100%);
    }

    .form__container {
      display: block;
      background: transparent;
    }

    .form__content {
      padding: 0;
    }

    .form__fields {
      width: 100%;
      max-width: 100%;
    }
  }

  &.opened,
  .opened {
    opacity: 1;
    visibility: visible;
  }
}

.form {
  &__container {
    button {
      width: 100%;

      @include media($md) {
        font-size: 14px;
      }
    }

    img {
      width: 100%;
    }
  }

  &__heading {
    margin-bottom: 40px;
    @include font(35px, 400, 43px, $black);
    @extend .unbounded;

    @include media($md) {
      margin-bottom: 25px;
      font-size: 25px;
      line-height: 31px;
    }

    &_small {
      font-size: 31px;
      text-align: center;

      @include media($md) {
        font-size: 21px;
      }
    }
  }

  &__fields {
    margin-bottom: 40px;
    @include flex($gap: 20px, $flow: column);

    @include media($md) {
      margin-bottom: 25px;
      gap: 15px;
    }
  }

  &__input {
    width: 100%;
    @include flex($gap: 5px, $flow: column);

    label {
      @include font(12px, 500, 21px, $black);
    }

    input {
      width: 100%;
      padding: 14px 20px;
      @include font(16px, 500, 28px, $black);
      border: 1px solid $black;
      border-radius: 39px;
      &::placeholder {
        color: #989898;
      }

      @include media($md) {
        font-size: 12px;
        line-height: 21px;
      }
    }
  }

  &__block {
    width: 100%;
    margin: 40px 0;
    padding: 20px;
    background: $yellow;
    @include font(32px, 700, 55px, $black, center);

    @include media($md) {
      font-size: 18px;
      line-height: 31px;
    }
  }

  &__buttons {
    @include flex(center, flex-start, 20px, row nowrap);

    & > * {
      flex: 1;
    }
  }
}