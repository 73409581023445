.catalog {
  .site__heading {
    margin-bottom: 60px;

    @include media($md) {
      margin-bottom: 40px;
    }
  }

  &__block {
    border: 1px solid $black;

    .item {
      @include grid(.7fr 1fr, start);

      @include media($md) {
        display: block;
      }

      &:not(:last-child) {
        border-bottom: 10px solid $yellow;
      }

      &__image {
        position: relative;
        height: 100%;

        @include media($md) {
          width: 100%;
          height: auto;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: calc(100% + 10px);
          background: $black;

          @include media($md) {
            width: 100%;
            height: 1px;
            top: unset;
            bottom: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &:last-child {
        .item__image::after {
          height: 100%;

          @include media($md) {
            width: 100%;
            height: 1px;
          }
        }
      }

      &__meta {
        padding: 40px;
        @include flex($gap: 20px, $flow: column);

        @include media($md) {
          padding: 20px;
        }
      }

      &__heading {
        @extend .unbounded;
        @include font(40px, 400, 50px, $black);

        @include media($md) {
          font-size: 25px;
          line-height: 31px;
        }
      }

      &__subheading {
        margin-bottom: 5px;
        @include font(14px, 700, 24px, $black);
      }

      &__text {
        @include font(14px, 500, 24px, $black);

        &_secondary {
          color: $gray;
        }
      }

      &__parameters {
        @include flex($gap: 25px, $flow: row wrap);
      }

      &__rate {
        @include flex($gap: 2px);

        &-star {
          width: 20px;
          height: 20px;

          @include svgColored($gray, 100%);

          &_filled {
            @include svgColored($yellow, 100%);
          }
        }
      }
    }
  }
}