.hero {
  position: relative;
  height: 100vh;
  min-height: 700px;
  max-height: 842px;
  padding: 35px 0 48px;

  @include media($md) {
    padding: 24px 0 0;
    height: auto;
    max-height: unset;
    min-height: unset;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 73%;
      height: 100%;
      background: $yellow;
      z-index: 0;

      @include media($w1500) {
        width: 80.5%;
      }

      @include media($md) {
        width: 100%;
        height: 40%;
        top: unset;
        left: 0;
        right: 0;
        bottom: 31px;
      }
    }

    &-image {
      position: absolute;
      top: 0;
      right: 0;
      width: 73%;
      height: 100%;
      z-index: 1;

      @include media($md) {
        width: 220%;
        height: 45%;
        top: unset;
        left: 50%;
        right: unset;
        transform: translateX(-50%);
        bottom: 110px;
      }

      img {
        width: 100%;
      }
    }


  }

  .container {
    position: relative;
    height: 100%;
  }

  &__logo {
    max-width: 102px;
    margin-bottom: 67px;

    @include media($md) {
      margin: 0 auto 21px;
    }
  }

  &__heading {
    margin: 67px 0 30px;
    max-width: 700px;
    @include font(75px, 600, 93px, $black);

    @include media($md) {
      margin: 21px 0 30px;
      font-size: 40px;
      line-height: 50px;
      text-align: center;
    }
  }

  &__subheading {
    max-width: 490px;
    margin: 30px 0;
    @include font(20px, 500, 35px, $black);

    @include media($md) {
      margin: 30px 0 334px;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
    }
  }

  &__arrow {
    position: absolute;
    right: 0;
    bottom: 35px;
    width: 68px;
    height: 68px;
    display: block;
    z-index: 5;

    @include media($md) {
      display: none;
    }

    @include svgColored($black, 100%);
  }

  &__socials {
    margin-top: 97px;
    @include flex(center, flex-start, 38px, row nowrap);

    @include media($md) {
      display: none;
    }

    a {
      display: block;
      width: 39px;
      height: 39px;

      @include svgColored($gray, 100%);
    }
  }

  .site__button {
    position: relative;
    z-index: 6;
  }
}