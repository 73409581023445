.footer {
  position: relative;
  padding: 65px 0 85px;
  background: url('../images/footer-bg.png') center/cover no-repeat;

  @include media($md) {
    background: url('../images/footer-bg-mobile.png') center/cover no-repeat;
  }

  @include media($md) {
    padding: 31px 0 72px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    z-index: 1;
  }

  &__content {
    position: relative;
    z-index: 2;

    @include media($md) {
      text-align: center;
    }
  }

  &__subheading {
    margin-bottom: 111px;
    @include font(25px, 600, 43px, $yellow);

    @include media($md) {
      margin-top: -13px;
      margin-bottom: 250px;
      font-size: 16px;
    }
  }
}