@import "../mixins";
@import "../variables";

* {
  font-family: "Montserrat", sans-serif!important;
  font-variant-numeric: lining-nums proportional-nums;
  box-sizing: border-box;
}

html {
  background: $white;
  scroll-behavior: smooth;
}

body {
  background: $white;
}

a {
  text-decoration: none;
  transition: .3s ease;
}

img,
svg {
  width: 100%;
  max-width: 100%;
  font-size: 0;
  line-height: 0;
  transition: .3s ease;
}

.container {
  position: relative;
  max-width: calc(1246px + $containerPadding * 2);
  margin: 0 auto;
  padding: 0 $containerPadding;

  @include media($md) {
    padding: 0 $containerPaddingSmall;
  }
}

.app {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}

.site {
  &__block {
    margin: 107px 0;
    overflow: hidden;

    @include media($md) {
      margin: 46px 0;
    }
  }

  &__heading {
    margin: 0 0 20px;
    @extend .unbounded;
    @include font(60px, 400, 74px, $black);

    @include media($md) {
      font-size: 28px;
      line-height: 35px;
    }

    span {
      @extend .unbounded;
      color: $yellow;
    }

    &_wide {
      position: relative;
      padding: 30px 0;
      z-index: 1;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 200%;
        transform: translateX(-50%);
        height: 100%;
        background: $yellow;
        z-index: -1;
      }
    }

    &_white {
      color: $white;
    }
  }

  &__button {
    width: fit-content;
    padding: 15px 30px;
    @include font(20px, 600, 35px, $black, center);
    border: 2px solid $black;
    border-radius: 39px;
    cursor: pointer;

    @include media($md) {
      width: 100%;
      max-width: 380px;
      margin: 0 auto;
      padding: 15px 19px;
      font-size: 18px;
      line-height: 31px;
      text-align: center;
    }

    &-white {
      background: $white;
    }

    &-yellow {
      background: $yellow;
      border: unset;
    }
  }
}

.content {
  *:first-child {
    margin-top: 0;
  }

  p {
    @include font(16px, 500, 28px, $black);
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @include media($md) {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.restricted {
  position: relative;
  height: 100vh;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.66);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    @extend .unbounded;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 1137px;
    @include font(71px, 600, 88px, $white, center);
    z-index: 3;

    @include media($md) {
      font-size: 21px;
      line-height: 26px;
    }

    span {
      @extend .unbounded;
      width: fit-content;
      margin: 0 auto;
      padding: 0 40px;
      display: block;
      background: $yellow;
      color: $black;
    }
  }
}