.about {
  position: relative;

  .container {
    position: static;
    @include flex(flex-start, flex-end, 0, row nowrap);
  }

  &__content {
    width: 58%;
    flex: 0 0 58%;

    @include media($md) {
      width: 100%;
      flex: 0 0 100%;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 43%;
    flex: 0 0 43%;
    height: 100%;

    @include media($md) {
      position: relative;
      width: 100%;
      flex: 0 0 100%;
      margin-bottom: 20px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content p:last-child {
    position: relative;
    background: transparent;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      left: -5px;
      top: -3px;
      width: 80%;
      height: calc(100% + 6px);
      background: $yellow;
      z-index: -1;
    }
  }
}