$black: #000;
$white: #fff;
$yellow: #FFE500;
$gray: #898989;

$containerPadding: 15px;
$containerPaddingSmall: 20px;
$headerPadding: 40px;
$headerPaddingMedium: 30px;
$headerPaddingSmall: 20px;

$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1399px;
$xxxl: 1699px;
$w1500: 1499px;